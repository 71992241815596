@tailwind base;
@tailwind components;
@tailwind utilities;


.form-input {
    @apply py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border rounded-md border-gray-300 appearance-none focus:border-gray-900 focus:border-[1.5px] outline-none
} 
.form-label {
    @apply text-gray-700 text-sm
}

.text-blue {
    color: #2D3748;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

